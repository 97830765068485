<template>
  <section class="m-summary-wrapper">
    <div class="m-title">公司简介</div>
    <div class="m-divider" />
    <div class="m-text m-setting">
      {{
        name
      }}，是作业帮旗下教育数字化综合服务商，秉持“科技赋能，让智慧教育触手可及”的使命，致力于以科技为杠杆，重塑数字化教与学新模式。公司将行业领先的人工智能、大数据、云计算等技术与教育教学场景深度融合，为政府和学校提供数字化资源、数字化应用、数字化治理等综合服务，支撑核心教育场景数字化转型。
    </div>
    <div class="m-text">
      截至目前，云思智学高质量作业、课后服务、智慧体育、智慧考试等核心产品已覆盖全国20多个省市自治区、超200个区县，服务学校超1500所、师生超100万人，有力推动教育数字化转型和教育高质量发展。
    </div>
    <div class="m-title top">公司领导</div>
    <div class="m-divider" />
    <div
      v-scroll-lazy="{ mode: 'bg', src: introimg }"
      class="m-intro-img"
    ></div>
    <div class="m-text m-setting bottom">
      苏静，作业帮执行总裁，北京云思智学科技有限公司董事长，毕业于中国人民大学法学院、英国诺丁汉大学，原百度公共政策研究院院长、原百度专利事务部高级总监。2018年北京市三八红旗手。北京市海淀区青联委员。
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import HOST from '@/common/host'
import img from './assets/sujing.png'
export default defineComponent({
  name: 'MobileSummary',
  setup() {
    const introimg = img

    let name = ''

    for (const item in HOST) {
      if (HOST[item].regex.test(location.hostname)) {
        name = HOST[item].name
      }
    }

    return {
      introimg,
      name
    }
  }
})
</script>

<style lang="less" scoped>
.m-summary-wrapper {
  display: block;
  width: 100%;
  background: #fff;
  padding: 1rem 0.36rem;
  .m-title {
    font-size: 0.56rem;
    font-weight: bold;
    color: #333;
  }
}
.m-divider {
  width: 0.4rem;
  height: 0.04rem;
  background: #333;
  margin-top: 0.19rem;
}
.m-text {
  font-size: 0.3rem;
  color: #666;
  line-height: 0.48rem;
}
.m-setting {
  margin: 0.4rem 0;
}
.m-intro-img {
  width: 100%;
  height: 4.2rem;
  margin-top: 0.4rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // border-radius: 0.1rem;
}
.top {
  margin-top: 0.9rem;
}
.bottom {
  margin-bottom: 0;
}
</style>
